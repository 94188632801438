<template>
  <div style="text-align: justify">
    <p class="mb-3">
      In this experiment, a spectroscope is used to look at the emission spectrum of an unknown
      metal. A sodium lamp is used as a calibration light source to determine the d value of the
      diffraction grating of the spectroscope
    </p>

    <p class="mb-2">
      Please enter your exercise number below. Without this number, you will automatically receive a
      grade of 0 on your prelab. If this does not match the number on the prelab you hand in, you
      will receive a grade of 0.
    </p>

    <calculation-input
      v-model="inputs.exerciseNumber"
      class="mb-7"
      prepend-text="$\text{Exercise Number:}$"
      dense
      :disabled="!allowEditing"
    />

    <p class="mb-3">The following experimental data are given:</p>

    <v-simple-table style="max-width: 720px" class="pl-8 mb-5">
      <thead>
        <tr>
          <td>Sodium, the calibration source, emission wavelength</td>
          <td v-if="exNumber >= 101 && exNumber <= 900">
            <latex-number :number="sodiumWL" unit="\text{nm}" />
          </td>
          <td v-else style="font-weight: bold; color: #c50000">Invalid Exercise Number</td>
        </tr>

        <tr>
          <td>Angle of light diffraction using the sodium lamp</td>
          <td v-if="exNumber >= 101 && exNumber <= 900">
            <latex-number :number="sodiumAngle" unit="^\circ" />
          </td>
          <td v-else style="font-weight: bold; color: #c50000">Invalid Exercise Number</td>
        </tr>

        <tr>
          <td>Angle of light diffraction using the unknown metal source</td>
          <td v-if="exNumber >= 101 && exNumber <= 900">
            <latex-number :number="metalAngle" unit="^\circ" />
          </td>
          <td v-else style="font-weight: bold; color: #c50000">Invalid Exercise Number</td>
        </tr>
      </thead>
    </v-simple-table>

    <p class="mb-3">
      Refer to Experiment 6 in the Chemistry 1110 lab manual for the equation relating the d value
      of the spectroscope to the wavelength and angle of diffraction and also for the Rydberg
      equation.
    </p>

    <p class="mb-3">1. Using the experimental data, determine:</p>

    <p class="mb-2">
      a) the distance (in nm) between the lines of the diffraction grating using the calibration
      source, the sodium lamp. Show your units.
    </p>

    <calculation-input
      v-model="inputs.dValue"
      append-text="$\text{nm}$"
      class="mb-5"
      :disabled="!allowEditing"
    />

    <p class="mb-2">
      b) the wavelength (in nm) of the radiation from the unknown metal source. Show your units.
    </p>

    <calculation-input
      v-model="inputs.lambdaUnk"
      append-text="$\text{nm}$"
      class="mb-6"
      :disabled="!allowEditing"
    />

    <p class="mb-3">
      2. Using the Rydberg equation and the value of the Rydberg constant given in the lab manual,
      calculate the wavelength (in nm) of the radiation emitted when an electron falls from energy
      level <latex-number :number="energyLevel2" /> to energy level
      <latex-number :number="energyLevel1" unit="\text{.}" /> Hint: Calculate the reciprocal of the
      wavelength directly from the equation and then take the reciprocal of that value to find the
      answer. Show your units.
    </p>

    <v-row>
      <v-col cols="5">
        <calculation-input
          v-model="inputs.lambdaEm"
          prepend-text="$\lambda:$"
          :disabled="!allowEditing"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field v-model="inputs.units" prefix="Units:" outlined :disabled="!allowEditing" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import {seededRandomBetween} from '@/courses/utils/seededShuffle';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'UPEISpectroscopePreLabV2',
  components: {
    CalculationInput,
    LatexNumber,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        exerciseNumber: null,
        dValue: null,
        lambdaUnk: null,
        lambdaEm: null,
      },
    };
  },
  computed: {
    exNumber() {
      if (this.inputs.exerciseNumber) {
        return this.inputs.exerciseNumber;
      } else {
        return 0;
      }
    },
    sodiumWL() {
      return seededRandomBetween(this.exNumber, 550, 570, 1);
    },
    sodiumAngle() {
      return seededRandomBetween(this.exNumber * 2, 16, 19, 2);
    },
    metalAngle() {
      return seededRandomBetween(this.exNumber * 3, 15, 18, 2);
    },
    energyLevel1() {
      return seededRandomBetween(this.exNumber * 4, 1, 2, 0);
    },
    energyLevel2() {
      return seededRandomBetween(this.exNumber * 5, 3, 5, 0);
    },
  },
};
</script>
